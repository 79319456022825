/* ChatMessageItem.css */

.ingredients {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
}

.ingredients li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border-radius: 37.5px;
  color: black;
  background: white; /* Use 'white' or any desired background color */
  margin: 1px; /* Half of the gap value to create the gap between list items */
  position: relative; /* Create a relative positioning context */
  font-family: 'Inter', sans-serif; /* Replace 'Your Font Name' with your desired font */
  text-transform: capitalize;
  font-size: 12px;
}
.disclaimer {
  font-weight: bold; /* This will make the text bold */
  font-size: 10px;
  color: white; /* This sets the default text color to white for .disclaimer */
}

.disclaimer a {
  color: white; /* This ensures that links are also white */
  text-decoration: underline; /* Optional: if you want to underline the links */
}
